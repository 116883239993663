import calculateAge from "./calculateAge";
import convertHexToRGB from "./convertHexToRGB";
import formatDateOfBirth from "./formatDateOfBirth";
import formatPhoneNumber from "./formatPhoneNumber";
import getCareteamInitials from "./getCareTeamInitials";
import getConsultCardPriority from "./getConsultCardPriority";
import getInitialsFromFullName from "./getInitialsFromFullName";
import { getMessageTimeAgo } from "./getMessageTimeAgo";
import getModalityType from "./getModalityType";
import getSenderName from "./getSenderName";
import getVisitReason from "./getVisitReason";
import hasNewNotes from "./hasNewNotes";
import isConsultInProgress from "./isConsultInProgress";
import mapClinicianToAllergy from "./mapClinicianToAllergy";
import parseConsultText from "./parseConsultText";

export {
    calculateAge,
    convertHexToRGB,
    formatDateOfBirth,
    formatPhoneNumber,
    getConsultCardPriority,
    getInitialsFromFullName,
    getCareteamInitials,
    getMessageTimeAgo,
    getModalityType,
    getVisitReason,
    getSenderName,
    hasNewNotes,
    isConsultInProgress,
    mapClinicianToAllergy,
    parseConsultText,
};
